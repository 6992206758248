export default {
  headers: [
    {
      id: 'miniHeader',
      logo: {
        alt: 'logo',
        src: '',
      },
      breakpoint: '',
      desktopMenu: {
        type: 'default', // default, split, center, single, one-offs
        disableOnHoverMenuExpand: false,
        logo: {
          show: false,
          reverseOrder: false,
          containerClassName: '',
          imageClassName: '',
        },
        headerClassName: 'on-olib-miniHeader',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        itemClassName: 'on-olib-miniHeader-item',
        menuClassName: '',
        menuLabelActiveClassName: '',
        menuItemClassName: '',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [
          {
            id: 'header-mail-item',
            iconSet: 'fa',
            className: 'envelope',
            content: 'Contact',
            url: 'https://www.j2regroup.com/contact.php',
          },
          {
            id: 'header-fb-item',
            iconSet: 'fab',
            className: 'facebook-f',
            url: 'https://www.facebook.com/JustinJohnsonRealtor',
          },
          {
            id: 'header-twitter-item',
            iconSet: 'fab',
            className: 'twitter',
            url: 'https://www.twitter.com/justinjohnsonre',
          },
          {
            id: 'header-youtube-item',
            iconSet: 'fab',
            className: 'youtube',
            url: 'https://www.youtube.com/channel/UCySvtbuK60fnePyYwDzpFjw/featured',
          },
          {
            id: 'header-instagram-item',
            iconSet: 'fab',
            className: 'instagram-square',
            url: 'https://www.instagram.com/justin_m_johnson',
          },
        ],
      },
      mobileMenu: {
        logo: {
          show: false,
          containerClassName: '',
          imageClassName: 'on-olib-header-logo',
        },
        type: 'mobile-content', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          className: '',
          content: 'Open',
          hide: true,
        },
        headerClassName: 'on-olib-miniHeader-mobile',
        headerClassNameOverride: '',
        mobileMenuClassName: '',
        itemClassName: 'on-olib-miniHeader-mobile-item',
        menuClassName: '',
        menuLabelActiveClassName: '',
        menuItemClassName: '',
        subMenuClassName: '',
        subMenuItemClassName: '',
        headerItems: [
          {
            id: 'header-mail-item',
            iconSet: 'fa',
            className: 'envelope',
            content: 'Contact',
            url: 'https://www.j2regroup.com/contact.php',
          },
          {
            id: 'header-fb-item',
            iconSet: 'fab',
            className: 'facebook-f',
            url: 'https://www.facebook.com/JustinJohnsonRealtor',
          },
          {
            id: 'header-twitter-item',
            iconSet: 'fab',
            className: 'twitter',
            url: 'https://www.twitter.com/justinjohnsonre',
          },
          {
            id: 'header-youtube-item',
            iconSet: 'fab',
            className: 'youtube',
            url: 'https://www.youtube.com/channel/UCySvtbuK60fnePyYwDzpFjw/featured',
          },
          {
            id: 'header-instagram-item',
            iconSet: 'fab',
            className: 'instagram',
            url: 'https://www.instagram.com/justin_m_johnson',
          },
        ],
        items: [],
      },
    },
    {
      id: 'mainHeader',
      logo: {
        alt: 'logo',
        src: 'https://si-homelight.s3.amazonaws.com/sites/olib/logo.png',
        url: 'https://www.j2regroup.com/index.php',
      },
      breakpoint: 992,
      desktopMenu: {
        type: 'default', // default, split, center, single, one-offs
        disableOnHoverMenuExpand: false,
        logo: {
          show: true,
          reverseOrder: false,
          containerClassName: 'on-olib-logo-container',
          imageClassName: 'on-olib-logo',
        },
        headerClassName: 'on-olib-desktop-header',
        headerSectionsClassName: 'on-olib-desktop-header-section',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        itemClassName: 'on-olib-desktop-item',
        menuClassName: 'on-olib-menu',
        menuLabelActiveClassName: 'on-olib-menu-active',
        menuItemClassName: 'on-olib-menu-item',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [
          {
            id: 'search-item',
            content: 'SEARCH',
            url: 'https://www.j2regroup.com/index.php?advanced=1&types%5B0%5D=1&types%5B1%5D=2&types%5B2%5D=4&types%5B3%5D=8&types%5B4%5D=9&types%5B5%5D=15&types%5B6%5D=18&beds=0&baths=0&min=0&max=100000000&rtype=grid',
          },
          {
            id: 'sell-item',
            content: 'SELL',
            url: 'https://www.j2regroup.com/sell.php',
          },
          {
            id: 'finance-item',
            content: 'FINANCE',
            url: 'https://www.j2regroup.com/finance.php',
          },
          {
            id: 'agents-item',
            content: 'AGENTS',
            url: 'https://www.j2regroup.com/agents.php',
            menuItems: [
              {
                id: 'agents-item-roster',
                content: 'Agent Roster',
                url: 'https://www.j2regroup.com/agents.php',
              },
              {
                id: 'agents-item-hiring',
                content: "We're Hiring!",
                url: 'https://www.j2regroup.com/recruit.php',
              },
              {
                id: 'agents-item-testimonials',
                content: 'Testimonials',
                url: 'https://www.j2regroup.com/testimonials.php',
              },
              {
                id: 'agents-item-blog',
                content: 'Blog',
                url: 'https://www.j2regroup.com/blog.php',
              },
            ],
          },
          {
            id: 'resources-item',
            content: 'RESOURCES',
            url: 'https://www.j2regroup.com/resources.php',
            menuItems: [
              {
                id: 'resources-item-our',
                content: 'Our Resources',
                url: 'https://www.j2regroup.com/resources.php',
              },
              {
                id: 'resources-item-contact',
                content: 'Contact',
                url: 'https://www.j2regroup.com/contact.php',
              },
            ],
          },
          {
            id: 'exclusive-item',
            content: 'EXCLUSIVE HOMELIGHT TRADE-IN PROGRAM',
            url: 'http://trade-in.j2regroup.com/',
          },
        ],
      },
      mobileMenu: {
        logo: {
          show: true,
          containerClassName: '',
          imageClassName: 'on-olib-logo-mobile',
        },
        type: 'mobile-default', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          type: 'hamburger',
          className: 'on-olib-hamburger',
          hide: false,
          reverse: false,
        },
        headerClassName: 'on-olib-mobile-header',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        mobileMenuClassName: 'on-olib-mobile-menu',
        itemClassName: 'on-olib-mobile-item',
        menuClassName: 'on-olib-mobile-folder',
        menuLabelActiveClassName: 'on-olib-folder-active',
        menuItemClassName: 'on-olib-mobile-folder-item',
        subMenuClassName: '',
        subMenuItemClassName: '',
        headerItems: [],
        items: [
          {
            id: 'search-item',
            content: 'SEARCH',
            url: 'https://www.j2regroup.com/index.php?advanced=1&types%5B0%5D=1&types%5B1%5D=2&types%5B2%5D=4&types%5B3%5D=8&types%5B4%5D=9&types%5B5%5D=15&types%5B6%5D=18&beds=0&baths=0&min=0&max=100000000&rtype=grid',
          },
          {
            id: 'sell-item',
            content: 'SELL',
            url: 'https://www.j2regroup.com/sell.php',
          },
          {
            id: 'finance-item',
            content: 'FINANCE',
            url: 'https://www.j2regroup.com/finance.php',
          },
          {
            id: 'agents-item',
            content: 'AGENTS',
            menuItems: [
              {
                id: 'agents-item-roster',
                content: 'Agent Roster',
                url: 'https://www.j2regroup.com/agents.php',
              },
              {
                id: 'agents-item-hiring',
                content: "We're Hiring!",
                url: 'https://www.j2regroup.com/recruit.php',
              },
              {
                id: 'agents-item-testimonials',
                content: 'Testimonials',
                url: 'https://www.j2regroup.com/testimonials.php',
              },
              {
                id: 'agents-item-blog',
                content: 'Blog',
                url: 'https://www.j2regroup.com/blog.php',
              },
            ],
          },
          {
            id: 'resources-item',
            content: 'RESOURCES',
            menuItems: [
              {
                id: 'resources-item-our',
                content: 'Our Resources',
                url: 'https://www.j2regroup.com/resources.php',
              },
              {
                id: 'resources-item-contact',
                content: 'Contact',
                url: 'https://www.j2regroup.com/contact.php',
              },
            ],
          },
          {
            id: 'exclusive-item',
            content: 'EXCLUSIVE HOMELIGHT TRADE-IN PROGRAM',
            url: 'http://trade-in.j2regroup.com/',
          },
        ],
      },
    },
  ],
  footers: [
    {
      id: 'on-olib-main-footer',
      className: 'on-olib-footer on-olib-main-footer',
      sectionClassName: 'on-olib-main-footer-container',
      columns: [
        {
          id: 'main-footer-column-one',
          className: 'on-olib-main-footer-column',
          items: [
            {
              id: 'main-footer-heading',
              type: 'heading',
              elementType: 'h1',
              data: 'The Johnson Real Estate Team at Big Block Realty North',
            },
            {
              id: 'main-footer-address-one',
              type: 'heading',
              elementType: 'p',
              className: 'olib-address',
              style: { lineHeight: 'normal' },
              data: '2007 Opportunity Dr #11',
            },
            {
              id: 'main-footer-address-two',
              type: 'heading',
              className: 'olib-address',
              elementType: 'p',
              style: { lineHeight: 'normal', paddingBottom: '3px' },
              data: 'Roseville , CA 95678',
            },
            {
              id: 'main-footer-phone',
              type: 'heading',
              style: { marginBottom: '20px' },
              data: '916-694-0437',
              url: 'tel:916-694-0437',
            },
            {
              id: 'main-footer-item-assistance',
              type: 'itemContent',
              className: 'on-olib-main-footer-item-assistance',
              items: [
                {
                  content:
                    'Should you require assistance in navigating our website or searching for real estate, please contact our offices at ',
                },
                {
                  content: '916-694-0437.',
                  url: 'tel:916-694-0437',
                },
              ],
            },
            {
              id: 'main-footer-socials',
              type: 'socialIcons',
              className: 'on-olib-main-footer-socials',
              items: [
                {
                  id: 'olib-footer-youtube',
                  iconTypeClassName: 'fab',
                  iconClassName: 'youtube',
                  url: 'https://www.youtube.com/channel/UCySvtbuK60fnePyYwDzpFjw/featured',
                },
                {
                  id: 'olib-footer-facebook',
                  iconTypeClassName: 'fab',
                  iconClassName: 'facebook-f',
                  url: 'https://www.facebook.com/JustinJohnsonRealtor',
                },
                {
                  id: 'olib-footer-twitter',
                  iconTypeClassName: 'fab',
                  iconClassName: 'twitter',
                  url: 'https://www.twitter.com/justinjohnsonre',
                },
                {
                  id: 'olib-footer-instagram',
                  iconTypeClassName: 'fab',
                  iconClassName: 'instagram',
                  url: 'https://www.instagram.com/justin_m_johnson',
                },
              ],
            },
          ],
        },
        {
          id: 'main-footer-column-two',
          className: 'on-olib-main-footer-column',
          items: [
            {
              id: 'main-footer-heading-disclaimer',
              type: 'heading',
              elementType: 'h1',
              data: 'MLS® Disclaimer',
            },
            {
              id: 'main-footer-metro-item',
              type: 'itemContent',
              className: 'on-olib-footer-disclaimer',
              items: [
                {
                  imageUrl: 'https://si-homelight.s3.amazonaws.com/sites/olib/logo-footer-metrolist.png',
                  imageClassName: 'on-olib-metrolist-logo',
                },
                {
                  content:
                    ' All measurements and all calculations of area are approximate. Information provided by Seller/Other sources, not verified by Broker. All interested persons should independently verify accuracy of information. Provided properties may or may not be listed by the office/agent presenting the information. Data maintained by MetroList© may not reflect all real estate activity in the market. All real estate content on this site is subject to the Federal Fair Housing Act of 1968, as amended, which makes it illegal to advertise any preference, limitation or discrimination because of race, color, religion, sex, handicap, family status or national origin or an intention to make any such preference, limitation or discrimination. MetroList CA data last updated: June 9, 2022 5:36 PM UTC  ',
                },
                {
                  content: 'Terms of Use.',
                  url: 'https://drive.google.com/file/d/1wGxyzWtCO8CKUxJ-thoL9j4KfKi5fDNY/view?usp=sharing',
                },
                {
                  content: ' Vendor data provided by Inside Real Estate, LLC. ',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'on-olib-mini-footer',
      className: 'on-olib-footer on-olib-footer-two',
      sectionClassName: 'on-olib-mini-footer-container',
      columns: [
        {
          id: 'mini-footer-column-one',
          className: 'on-olib-mini-footer-column',
          items: [
            {
              id: 'mini-footer-item-inside-real-estate',
              type: 'itemContent',
              items: [
                {
                  content: '© 2022 Inside Real Estate',
                  url: 'https://insiderealestate.com/',
                },
              ],
            },
            {
              id: 'mini-footer-item-terms-of-use',
              type: 'itemContent',
              items: [
                {
                  content: 'Terms of Use',
                  url: 'https://www.j2regroup.com/terms.php',
                },
              ],
            },
            {
              id: 'mini-footer-item-privacy-policy',
              type: 'itemContent',
              items: [
                {
                  content: 'Privacy Policy',
                  url: 'https://www.j2regroup.com/privacy.php',
                },
              ],
            },
            {
              id: 'mini-footer-item-accessibility',
              type: 'itemContent',
              items: [
                {
                  content: 'Accessibility',
                  url: 'https://www.j2regroup.com/accessibility.php',
                },
              ],
            },
            {
              id: 'mini-footer-item-fair-housing-statement',
              type: 'itemContent',
              items: [
                {
                  content: 'Fair Housing Statement',
                  url: 'https://www.j2regroup.com/fair.php',
                },
              ],
            },
            {
              id: 'mini-footer-item-realtor',
              type: 'image',
              url: 'https://si-homelight.s3.amazonaws.com/sites/olib/logo-footer-equal.png',
            },
          ],
        },
      ],
    },
  ],
  substitute: {},
  replace: {
    'tradeIn.hero.className': 'heroClassName',
    'cashOffer.hero.className': 'heroClassName',
  },
  siteData: {
    agency: 'The Johnson Real Estate Team at Big Block Realty North',
    agentDescription: 'Top agents in Placer & Sacramento, CA',
    agentAvatar: 'https://si-homelight.s3.amazonaws.com/sites/olib/agent.jpg',
    heroBackground: 'https://si-homelight.s3.amazonaws.com/sites/olib/hero-bg.webp',
    heroClassName: 'on-olib-hero',
  },
};
